const MonsterNames = [
  { lastName: 'Applegnash' },
  { lastName: 'Ahhhsome' },
  { lastName: 'Angerling' },
  { lastName: 'Aquard' },
  { lastName: 'Antchovie' },
  { lastName: 'Aquabat' },
  { lastName: 'Ailment' },
  { lastName: 'Axebite' },
  { lastName: 'Animalankles' },
  { lastName: 'Bile' },
  { lastName: 'Blinkington' },
  { lastName: 'Badwater' },
  { lastName: 'Boleslaw' },
  { lastName: 'Beetlebrain' },
  { lastName: 'Burstbubble' },
  { lastName: 'Boulderball' },
  { lastName: 'Bumpy' },
  { lastName: 'Cage' },
  { lastName: 'Chillingsberg' },
  { lastName: 'Clawson' },
  { lastName: 'Cretanus' },
  { lastName: 'Clank' },
  { lastName: 'Chum' },
  { lastName: 'Dungworth' },
  { lastName: "D'Vour" },
  { lastName: 'Doorley' },
  { lastName: 'Droolie' },
  { lastName: 'Danger' },
  { lastName: "D'Ungliton" },
  { lastName: 'Eekswerth' },
  { lastName: 'Eversnoot' },
  { lastName: 'Eyegout' },
  { lastName: 'Eggspleen' },
  { lastName: 'Eyeseinson' },
  { lastName: 'Evaporsnake' },
  { lastName: 'Emergencyseed' },
  { lastName: 'Eww' },
  { lastName: 'Fungus' },
  { lastName: 'Fangwilder' },
  { lastName: 'Furryfoot' },
  { lastName: 'Funky' },
  { lastName: 'Fistprowler' },
  { lastName: 'Fowl' },
  { lastName: 'Gnawview' },
  { lastName: 'Gross' },
  { lastName: 'Growlahan' },
  { lastName: 'Goretega' },
  { lastName: 'Ghostcrusher' },
  { lastName: 'Gooie' },
  { lastName: 'Gumdump' },
  { lastName: 'Horntooth' },
  { lastName: 'Hairaiser' },
  { lastName: 'Howl' },
  { lastName: 'Harker' },
  { lastName: 'Hurl' },
  { lastName: 'Hunkyteeth' },
  { lastName: 'Hammer' },
  { lastName: 'Horrid' },
  { lastName: 'Icesplitter' },
  { lastName: 'Ickslime' },
  { lastName: 'Irkmore' },
  { lastName: 'Icescream' },
  { lastName: 'Inkstains' },
  { lastName: 'Idlebot' },
  { lastName: 'Ignight' },
  { lastName: 'Jelly' },
  { lastName: 'Jinksman' },
  { lastName: 'Jankelman' },
  { lastName: 'Jawbone' },
  { lastName: 'Jangerling' },
  { lastName: 'Juice' },
  { lastName: 'Jackle' },
  { lastName: 'Juggles' },
  { lastName: 'Knuckleheimer' },
  { lastName: 'Knightwilder' },
  { lastName: 'Knicknack' },
  { lastName: 'Knuckles' },
  { lastName: 'Klobber' },
  { lastName: 'Krusty' },
  { lastName: 'Kanepatches' },
  { lastName: 'Littlebeast' },
  { lastName: 'Lurkington' },
  { lastName: 'Longslime' },
  { lastName: 'Lemoncurd' },
  { lastName: 'Lurchmon' },
  { lastName: 'Leadlegs' },
  { lastName: 'Longsloth' },
  { lastName: 'Muddyham' },
  { lastName: 'Mussfur' },
  { lastName: 'McNaughton' },
  { lastName: 'Maulsalot' },
  { lastName: 'Mumkin' },
  { lastName: 'McRoaring' },
  { lastName: 'MacHairaiser' },
  { lastName: 'Needleman' },
  { lastName: 'Narrownose' },
  { lastName: 'Napington' },
  { lastName: 'Nomnom' },
  { lastName: 'Noodle' },
  { lastName: 'Nibbles' },
  { lastName: 'Nectarneck' },
  { lastName: "O'Growlahan" },
  { lastName: 'Odorfoot' },
  { lastName: 'Oozeman' },
  { lastName: 'Oaffleson' },
  { lastName: 'Oozerson' },
  { lastName: "O'Rohr" },
  { lastName: 'Pewstench' },
  { lastName: 'Patchtail' },
  { lastName: 'Prowlman' },
  { lastName: 'Pemberslime' },
  { lastName: 'Pepperstinch' },
  { lastName: 'Pickle' },
  { lastName: 'Peeyew' },
  { lastName: 'Quainthaven' },
  { lastName: 'Queasyhorn' },
  { lastName: 'Quicknoggin' },
  { lastName: 'Quankster' },
  { lastName: 'Quacky' },
  { lastName: 'Quirkmyster' },
  { lastName: 'Quinch' },
  { lastName: 'Quiver' },
  { lastName: 'Querate' },
  { lastName: 'Roaring' },
  { lastName: 'Rompleston' },
  { lastName: 'Rinkldipper' },
  { lastName: 'Rozmon' },
  { lastName: 'Rage' },
  { lastName: 'Rumblerat' },
  { lastName: 'Raptor' },
  { lastName: 'Reeking' },
  { lastName: 'Smellie' },
  { lastName: 'Shufflebottom' },
  { lastName: 'Shriekmore' },
  { lastName: 'Slimehorne' },
  { lastName: 'Scareburn' },
  { lastName: 'Shrill' },
  { lastName: 'Stillwater' },
  { lastName: 'Scrumm' },
  { lastName: 'Taileewagger' },
  { lastName: 'Toxfeld' },
  { lastName: 'Tremble' },
  { lastName: 'Tattletail' },
  { lastName: 'Tinymight' },
  { lastName: 'Teethy' },
  { lastName: 'Thump' },
  { lastName: 'Ugliton' },
  { lastName: 'Upadoopa' },
  { lastName: 'Urbone' },
  { lastName: 'Uglie' },
  { lastName: 'Uno' },
  { lastName: 'Unihorns' },
  { lastName: 'Venomseed' },
  { lastName: 'Verminfield' },
  { lastName: 'Von Irk' },
  { lastName: 'Van der Slime' },
  { lastName: 'Visciousness' },
  { lastName: 'Vulture' },
  { lastName: 'Violentence' },
  { lastName: 'Vaporraid' },
  { lastName: 'Waxford' },
  { lastName: 'Wormwood' },
  { lastName: 'Wooly' },
  { lastName: 'Wabble' },
  { lastName: 'Womperworm' },
  { lastName: 'Whipcscratch' },
  { lastName: 'Wiggles' },
  { lastName: 'Xingaroo' },
  { lastName: 'Xachoo' },
  { lastName: 'Xagtop' },
  { lastName: "X'ed" },
  { lastName: 'Xtastic' },
  { lastName: 'Xericrumbs' },
  { lastName: 'Yucatalico' },
  { lastName: 'Yiptail' },
  { lastName: 'Yornado' },
  { lastName: 'Yapper' },
  { lastName: 'Yellowtooth' },
  { lastName: 'Yumyum' },
  { lastName: 'Zitshriek' },
  { lastName: 'Zanadope' },
  { lastName: 'Zebrain' },
  { lastName: 'Zing' },
  { lastName: 'Zapper' },
  { lastName: 'Zoink' },
  { lastName: "Z'Bo" }
]

export default MonsterNames
