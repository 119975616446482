export default ImageData = [
  {
    id: 'type1',
    url: '../../images/monsters/type1/SKIN/COLORS/body_col_1.png'
  },
  {
    id: 'type2',
    url: '/images/monsters/type2/SKIN/COLORS/body_col_1.png'
  },
  {
    id: 'type3',
    url: '/images/monsters/type3/SKIN/COLORS/body_col_1.png'
  },
  {
    id: 'type4',
    url: '/images/monsters/type4/SKIN/COLORS/body_col_1.png'
  }
  // {
  //     id: 'type5',
  //     url: '/images/monsters/type5/SKIN/COLORS/body_col_1.png'
  // }
]
